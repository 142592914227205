/*
 * Copyright Vincent Blouin under the GPL License version 3
 */
import CurrentSubGraph from '@/graph/CurrentSubGraph'
import Store from '@/store'

const Color = {
    DEFAULT_BACKGROUND_COLOR: "#FFFFFF",
    EdgeColor: "#757575",
    LightGray: "#BDBDBD",
    LighterGray: "#E0E0E0",
    SkeletonColor: "#e8e8e8",
    getBackgroundColorForColor: function (color) {
        var hsl = Color.hex2Hsl(color);
        return 'hsl(' + hsl.h + ', ' + hsl.s + '%, ' + 96 + '%)';
    },
    getContrast: function (hexcolor) {
        // https://stackoverflow.com/a/11868398
        hexcolor = hexcolor.replace("#", "");
        let r = parseInt(hexcolor.substr(0, 2), 16);
        let g = parseInt(hexcolor.substr(2, 2), 16);
        let b = parseInt(hexcolor.substr(4, 2), 16);
        let yiq = ((r * 299) + (g * 587) + (b * 114)) / 1000;
        return (yiq >= 128) ? 'black' : 'white';
    },
    refreshBackgroundColor: function (backgroundColor) {
        let center = CurrentSubGraph.get().center;
        if (!backgroundColor && !center) {
            backgroundColor = Color.DEFAULT_BACKGROUND_COLOR
        } else {
            backgroundColor = backgroundColor || center.resolveBackgroundColor();
        }
        let drawnGraph = document.getElementById("drawn_graph");
        if (drawnGraph) {
            drawnGraph.style.background = "radial-gradient(rgba(0, 0, 0, 0) 15%, " + backgroundColor + " 100%"
        }
        let hsl = Color.hex2Hsl(backgroundColor);
        Color.bubbleBackground = 'hsl(' + hsl.h + ', ' + hsl.s + '%, ' + 96 + '%)';
        Store.dispatch('setBackgroundColor', Color.bubbleBackground);
    },
    hex2Hsl: function (hex) {
        //https://stackoverflow.com/q/46432335
        var result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);

        var r = parseInt(result[1], 16);
        var g = parseInt(result[2], 16);
        var b = parseInt(result[3], 16);

        r /= 255;
        g /= 255;
        b /= 255;
        var max = Math.max(r, g, b), min = Math.min(r, g, b);
        var h, s, l = (max + min) / 2;

        if (max === min) {
            h = s = 0; // achromatic
        } else {
            var d = max - min;
            s = l > 0.5 ? d / (2 - max - min) : d / (max + min);
            switch (max) {
                case r:
                    h = (g - b) / d + (g < b ? 6 : 0);
                    break;
                case g:
                    h = (b - r) / d + 2;
                    break;
                case b:
                    h = (r - g) / d + 4;
                    break;
            }
            h /= 6;
        }

        s = s * 100;
        s = Math.round(s);
        l = l * 100;
        l = Math.round(l);
        h = Math.round(360 * h);
        return {
            h: h,
            s: s,
            l: l
        };
    },
    bubbleBackground: ""
};
export default Color;
